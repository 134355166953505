import "./JockLanding.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/JockxSplash.svg";

const JockLanding = () => {
  return (
    <div className="jockContainer">
      <div className="header-container">
        <div className="icon-container">
          <img src={Logo} alt="Logo" className="icon" />
        </div>

        <div className="sub-header">Our Next Chapter:</div>
        <div className="main-header">
          Jock MKT Joining Forces with Splash Sports
        </div>
      </div>
      <div className="letter-container">
        <div className="letter">Jock MKT faithful,</div>
        <div className="letter">
          Thank you all for your contribution to our journey over the last 4
          years. Today, we are writing to let all of you know that we are
          excited to be joining forces with the team at Splash Sports, effective
          immediately.
        </div>
        <div className="letter">
          The last 4 years have been incredibly fulfilling, having the
          opportunity to build fantasy sports products that we and our users
          love. The journey started with our markets game and expanded into new
          games, sports, and even esports.
        </div>
        <div className="letter">
          Splash (owners of{" "}
          <a href="https://www.officefootballpool.com/" className="link-page">
            OfficeFootballPool
          </a>
          ,{" "}
          <a href="https://www.runyourpool.com/" className="link-page">
            RunYourPool
          </a>
          , and{" "}
          <a href="https://splashsports.com/" className="link-page">
            SplashSports.com
          </a>
          ) share our passion for innovating in fantasy sports and launching
          compelling games to make sports more entertaining. Splash has over 2
          million users and 80,000 commissioners across their brands and is
          known for peer-to-peer contests like Survivor, Pick'em, Confidence,
          One and Done and more. We look forward to working with them to launch
          new contest types that bring people together.
        </div>
        <div className="letter">
          Due to fantasy licensing rules and regulations, as of today, Jock MKT
          games are no longer operational, but we are excited to introduce you
          to the world of contests on Splash Sports. For anyone who converts
          their account, Splash will be offering you a $25 bonus for making the
          transition to Splash.
        </div>
        <div className="letter">
          If you choose not to play any games on Splash, you can withdraw from
          your Jock MKT account anytime over the next 15 days. After this 2 week
          window, your funds will be transferred over to Splash Sports, where
          you can log in to play or access your funds for withdrawal. You will
          receive an email with information about your account.
        </div>
        <div className="letter">
          For those of you in Pennsylvania, Mississippi, Missouri and Puerto
          Rico, unfortunately Splash Sports does not yet offer games in your
          area (though they are working diligently to add new states as quickly
          as possible). To access your funds, you can
          <a href="https://app.jockmkt.com/withdrawals"> click here </a> and
          will be redirected to your Jock MKT account page where you can
          withdraw funds as you normally would. Please note, the Jock MKT app
          will only be live for 15 days, so please withdraw before then. After
          15 days all funds will be transferred to Splash Sports, where they
          will be available for play or withdrawal.
        </div>
        <div className="letter">
          For those of you in Ohio, state law requires that Jock Mkt's former
          parent company, Bid Ventures, Inc., refund your money. Ohio residents
          should receive their refund within 15 days; however, if your payment
          source is no longer active or your payment information is incorrect
          and Bid Ventures is unable to issue a refund, your money will be
          transferred to the Ohio Department of Commerce Division of Unclaimed
          Funds. For any questions about the changes, what's coming next, or
          withdrawals/account access, please feel free to send an email to{" "}
          <a href="mailto:support@splashsports.com" className="link-page">
            support@splashsports.com
          </a>
          .
        </div>
        <div className="letter">
          Thanks again everyone for being a part of the Jock MKT journey. It may
          be the end of this chapter, but we are incredibly excited for the next
          one as a part of Splash Sports and think you all will be too. Stay
          tuned for more updates on the games we will be launching together.
          Can't wait for this NFL season!
        </div>
        <div className="letter">Onwards,</div>
        <div className="letter">The Jock MKT Team</div>
      </div>
      <Link to={"/withdrawals"} className="transferFundsLink">
        <button className="transferFunds-button">
          Transfer or Withdraw Funds
        </button>
      </Link>
    </div>
  );
};

export default JockLanding;
