import React, { useState } from "react";
import "./faqs.css";

const Faqs: React.FC = () => {
  const [expanded, setExpanded] = useState<string[]>([]);

  const handleChange = (panel: string) => () => {
    setExpanded((prevExpanded) =>
      prevExpanded.includes(panel)
        ? prevExpanded.filter((p) => p !== panel)
        : [...prevExpanded, panel]
    );
  };

  return (
    <div className="faqContainer">
      <div className="faq-title">Frequently Asked Questions</div>
      <div className="accordion-item">
        <div
          className="accordion-header"
          onClick={handleChange("panel1")}
          aria-expanded={expanded.includes("panel1")}
        >
          What happens when I transfer my account and/or funds to Splash Sports?
        </div>
        {expanded.includes("panel1") && (
          <div className="accordion-body">
            <p>
              We will create a Splash Sports account for you using your Jock MKT
              email address. If you want to get a head-start, download the
              Splash App using your Jock MKT email and the funds and bonus
              cash will be in your account on August 9th, 2024.
            </p>
          </div>
        )}
      </div>
      <div className="accordion-item">
        <div
          className="accordion-header"
          onClick={handleChange("panel2")}
          aria-expanded={expanded.includes("panel2")}
        >
          How long do I have to make a decision to transfer or withdraw my
          funds?
        </div>
        {expanded.includes("panel2") && (
          <div className="accordion-body">
            <p>
              The Jock MKT website will shut down on August 9th, 2024. Any
              funds not transferred will be mailed via check to the mailing
              address on file.
            </p>
          </div>
        )}
      </div>
      <div className="accordion-item">
        <div
          className="accordion-header"
          onClick={handleChange("panel3")}
          aria-expanded={expanded.includes("panel3")}
        >
          What is Splash Sports and what games does it offer?
        </div>
        {expanded.includes("panel3") && (
          <div className="accordion-body">
            <p>
              SplashSports is brought to you by the owners of RunYourPool and
              OfficeFootballPool, the leaders in Survivor, Pick'em, Confidence,
              One and Done, and DFS Tiers Pools. With Splash Sports, you can
              play these games as real-money contests, letting you play against
              your friends while we handle the entry fees and payouts.
            </p>
          </div>
        )}
      </div>
      <div className="accordion-item">
        <div
          className="accordion-header"
          onClick={handleChange("panel4")}
          aria-expanded={expanded.includes("panel4")}
        >
          The map shows that Splash Sports is available in my state but I can’t
          join any contests.
        </div>
        {expanded.includes("panel4") && (
          <div className="accordion-body">
            <p>
              First, confirm that you have location services enabled. Then
              complete verification of your account and make sure you have
              enough funds in your wallet to enter the desired contest. If you
              still have trouble finding a contest, it’s possible that the
              contest type you are looking for hasn’t started yet. For a
              detailed map of which contests are available in your state, click{" "}
              <a
                href="https://splashsports.com/where-can-you-play"
                style={{ color: "#61dafb" }}
              >
                Where can you play?
              </a>
            </p>
          </div>
        )}
      </div>
      <div className="accordion-item">
        <div
          className="accordion-header"
          onClick={handleChange("panel5")}
          aria-expanded={expanded.includes("panel5")}
        >
          Can I delete my account?
        </div>
        {expanded.includes("panel5") && (
          <div className="accordion-body">
            <p>
              All Jock MKT accounts will be closed on August 9th, 2024, but
              if you’d like your information deleted, please email{" "}
              <a
                href="mailto:privacy@splashsports.com"
                style={{ color: "#61dafb" }}
              >
                privacy@splashsports.com
              </a>{" "}
              with a note to say you wish to delete your account.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Faqs;
