import React from "react";
import Splashy from "../../assets/Splashy.svg";
import mobile from "../../assets/mobile.png";
import Frame1 from "../../assets/Frame 1.svg";
import Frame2 from "../../assets/Frame 2.svg";
import Frame3 from "../../assets/Frame 3.svg";
import Frame4 from "../../assets/Frame 4.svg";
import Frame5 from "../../assets/Frame 5.svg";
import Map_Jockmkt from "../../assets/Map_Jockmkt.svg";
import "./SplashLanding.css";
import { Link } from "react-router-dom";
import Faqs from "../../components/accordion/faqs";

const SplashLanding = () => {
  return (
    <>
      <div className="splashContainer">
        <div className="header-container2">
          <div className="icon-container2">
            <img src={Splashy} alt="SplashLogo" className="splashIcon" />
          </div>
          <div className="splashTagline">
            Splash Sports is the home of{" "}
            <span className="peers">peer-to-peer gaming</span>!
          </div>
          <p className="p1">
            Compete against friends or the community in skill-based games for
            real cash prizes!
          </p>
        </div>
        <div className="mobile-container">
          <img src={mobile} alt="mobileLogo" className="mobileLogo" />
        </div>
        <p className="p2">Offering Your Favorite Sports</p>
        <p className="p3">
          Splash Sports lets you compete in HUGE Survivor or fantasy contests
          across all the most popular sports and leagues:
        </p>
        <div className="frames">
          <img src={Frame1} alt="mobileLogo" className="frame" />
          <img src={Frame2} alt="mobileLogo" className="frame" />
          <img src={Frame3} alt="mobileLogo" className="frame" />
          <img src={Frame4} alt="mobileLogo" className="frame" />
          <img src={Frame5} alt="mobileLogo" className="frame" />
        </div>
        <p className="header2">Where can you play our games?</p>
        <div className="map">
          <img src={Map_Jockmkt} alt="mobileLogo" className="map" />
        </div>
        <div className="buttonGroup">
          <Link to={"/confirmation"} className="transferAccountsLink">
            <button className="transferAccounts-button">
              Transfer My Account & Funds
            </button>
          </Link>
          <div className="buttonTagline">
            If you choose to transfer your account (in good standing) and/or
            remaining funds on Jock MKT, we will give you $25 in Splash
            credits that you can use.{" "}
            <span>
              <a href="https://splashsports.com/" className="learn">
                Learn More
              </a>
            </span>
          </div>
        </div>
      </div>
      <Faqs />
    </>
  );
};

export default SplashLanding;
