import "./footer.css";

const Footer = () => {
  return (
    <div className="footer-section1">
      <div className="legalContainer">
        <div className="Legal">LEGAL AND SAFETY</div>
        <ul>
          <li>
            <a href="https://legal.splashsports.com/legal/terms-of-use">
              Terms of Use{" "}
            </a>
          </li>
          <li>
            <a href="https://legal.splashsports.com/legal/terms-of-use">
              Privacy Policy
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
