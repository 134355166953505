import React from "react";
import Splashy from "../../assets/Splashy.svg";
import "./Confirmation.css";
import Confetti_Black from "../../assets/Confetti_Black.png";

const Confirmation = () => {
  return (
    <>
      <div className="splashContainer3">
        <div className="header-container3">
          <div className="icon-container3">
            <img src={Splashy} alt="SplashLogo" className="splashIcon" />
          </div>
          <div className="header">
            You’re <span className="G2G">Good to Go</span>!
          </div>
          <div className="c1">
            Thank you! We’re excited to have you come play on Splash. There
            isn’t anything else that you need to do. We’ll be setting up your
            account, based on the email address that you used on JockMKT, so you
            just need to come in and verify to be ready to play. Please keep an
            eye out for an email from the team on August 9th.
          </div>
          <div className="confetti">
            <img
              src={Confetti_Black}
              alt="SplashConfetti"
              className="splashConfetti"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
