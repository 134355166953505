import "./App.css";
import Footer from "./components/footer/footer";
import ScrollToTop from "./components/scrollToTop/scrollToTop";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import JockLanding from "./pages/JockLanding/JockLanding";
import SplashLanding from "./pages/SplashLanding/SplashLanding";
import Confirmation from "./pages/Confirmation/Confirmation";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<JockLanding />} />
            <Route path="/withdrawals" element={<SplashLanding />} />
            <Route path="/confirmation" element={<Confirmation />} />
          </Routes>
        </ScrollToTop>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
